import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Badge = ({
  id,
  title,
  score,
  percentile,
  dateCompleted,
  level,
  localImage,
}) => (
  <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mt-4">
    <Link className="text-decoration-none" to={id}>
      <div className="p-4 bg-light h-100">
        <GatsbyImage image={localImage.childImageSharp.gatsbyImageData} alt={title} />
        <h4>{title}</h4>
        
        <div>Score: {score}</div>
        <div>Top: {Math.floor(percentile)}%</div>
        <div>Tested On: {moment(dateCompleted).format('MMMM DD YYYY')}</div>
        <div>Skill Level: {level}</div>
      </div>
    </Link>
  </div>
)

const Skills = ({ data }) => {
  const badges = data.allPluralsightJson.edges.map(({ node }) => (
    <Badge key={node.dateCompleted} {...node} />
  ))
  return (
    <Layout>
      <Seo title="Skills: Tested on Pluralsight" />
      <div className="container-fluid">
        <h1 className="col-12">Skills: Tested on Pluralsight</h1>
        <div className="col-12">
          <p>
            I have recently completed (October 2020) the AZ-204 certification to become a certified Azure Developer Associate.
          </p>
          <p>
            Pluralsight is an online learning platform. Here are results from my skillIQ tests.
          </p>
        </div>
        <div className="col-12">
          <div className="row">
            {badges}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Skills

export const query = graphql`{
  allPluralsightJson(sort: {fields: [score], order: DESC}) {
    edges {
      node {
        id
        percentile
        title
        dateCompleted(fromNow: false)
        level
        localImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        score
      }
    }
  }
}
`
